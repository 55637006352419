export enum ACCOUNT_TYPE {
  REAL = 'real',
  VIRTUAL = 'virtual',
}

export type AccountType = ACCOUNT_TYPE.REAL | ACCOUNT_TYPE.VIRTUAL;

export enum MODAL_TYPE {
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  TRANSACTION = 'TRANSACTION',
  AUTO_ORDER = 'AUTO_ORDER',
  AUTOBUY_TNC = 'AUTOBUY_TNC',
  TRADING_LIMIT_TNC = 'TRADING_LIMIT_TNC',
  SPLIT_ORDER_INFO = 'SPLIT_ORDER_INFO',
  SPLIT_ORDER_METHOD_INFO = 'SPLIT_ORDER_METHOD_INFO',
  ORDER_TYPE = 'ORDER_TYPE',
  PREVIEW = 'PREVIEW',
  EXCHANGE_FEE = 'EXCHANGE_FEE',
  ORDER_PLACED = 'ORDER_PLACED',
  SPECIAL_MONITORING = 'SPECIAL_MONITORING',
  DAY_TRADE_ONBOARDING = 'DAY_TRADE_ONBOARDING',
  DAY_TRADE_TNC = 'DAY_TRADE_TNC',
  DAY_TRADE_CONFIRMATION = 'DAY_TRADE_CONFIRMATION',
  DAY_TRADE_UNAVAILABLE = 'DAY_TRADE_UNAVAILABLE',
  SHARIA_UNAVAILABLE = 'SHARIA_UNAVAILABLE',
}

export type ModalType = MODAL_TYPE | null;

export enum LEVERAGE {
  DAY_TRADE = 'day-trade',
  REGULAR = 'regular',
}
export interface iFormData {
  orderType: string;
  orderid?: string;
  currentSymbol: string;
}
